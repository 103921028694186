<template>
  <Index>
    <div v-wechat-title="$route.meta.title"></div>
    <div id="main">
      <!-- 左侧栏 -->
      <div class="left_tab">
        <div class="left_tab_width">
          <!-- 幻灯片 -->
          <el-carousel
            height="584px"
            class="carousel"
          >
            <el-carousel-item>
              <img
                src="../assets/carousel4.jpg"
                alt=""
              >
            </el-carousel-item>
            <el-carousel-item>
              <img
                src="../assets/carousel1.jpg"
                alt=""
              >
            </el-carousel-item>
            <el-carousel-item>
              <img
                src="../assets/carousel3.jpg"
                alt=""
              >
            </el-carousel-item>
          </el-carousel>

          <!-- 文章列表 -->
          <ArticleListInfo ref="ArticleListInfo"></ArticleListInfo>
        </div>
      </div>
      <!-- 右侧栏 -->
      <div class="right_tab">
        <!-- 时间 -->
        <div class="timeCard">
          <p>{{ time }}</p>
        </div>
        <!-- 博客介绍 -->
        <div class="introduceCard">
          <p class="introduceTitle">叶德钦的博客</p>
          <p class="introduceContent">
            &nbsp;&nbsp;&nbsp;&nbsp;一个喜欢写代码的博主，喜欢去探索未知领域，喜欢学习新内容，喜欢在代码的世界中遨游。目标成为一名优秀的前端开发工程师。</p>
        </div>
        <!-- 联系方式 -->
        <div class="smallCard">
          <div class="relativeClass">
            <img
              src="../assets/qq.png"
              alt="qq"
              class="contactIcon"
              id="qq"
            />
            <div class="qqPopUp">
              <img
                src="../assets/qqQRcode.png"
                alt=""
                class="QRcodeImHeight"
              />
            </div>
          </div>
          <img
            src="../assets/gitee.png"
            alt="gitee"
            class="contactIcon"
          />
          <div class="relativeClass">
            <img
              src="../assets/wechat.png"
              alt="wechat"
              class="contactIcon"
              id="wechat"
            />
            <div class="wechatPopUp">
              <img
                src="../assets/wechatQRcode.png"
                alt=""
                class="QRcodeImHeight"
              />
            </div>
          </div>
        </div>
        <!-- 文章、标签、分类数量展示 -->
        <div class="smallCard">
          <div class="numIcon">
            <span>文章</span>
            <span class="nums">{{ blogNums.articleNums }}</span>
          </div>
          <div class="numIcon">
            <span>分类</span>
            <span class="nums">{{ blogNums.categoryNums }}</span>
          </div>
          <div class="numIcon">
            <span>标签</span>
            <span class="nums">{{ blogNums.tagNums }}</span>
          </div>
        </div>
        <div class="tags_categorys">
          <!-- 文章搜索框 -->
          <div class="smallCard">
            <el-input
              v-model="selectArticleFrom.title"
              placeholder="请输入关键词..."
              @change="onSearch()"
              clearable
            >
              <template #append>
                <span
                  style="cursor:pointer;"
                  @click="onSearch()"
                ><i class="fa fa-search"></i></span>
              </template>
            </el-input>
          </div>
          <!-- 展示所有标签 -->
          <div class="tagCard">
            <span
              v-for="item in allTags"
              :key="item.id"
              class="tagItem"
              @click="onChangeTag(item.id)"
            >{{
            item.tag_name
          }}</span>
          </div>
          <!-- 展示所有分类 -->
          <div class="categoryCard">
            <div
              v-for="item in allCategorys"
              :key="item.id"
              class="categroyItem"
              @click="onChangeCategory(item.id)"
            >
              <div class="categoryName">{{item.category_name}}</div>
              <hr
                v-if="item.id !== 1"
                class="categoryHr"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Index>
</template>

<script>
import { reactive, ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import ArticleListInfo from '../components/ArticleListInfo.vue'
import Index from '../components/Index.vue'
import {
  SELECTBLOGNUMS,
  SELECTALLTAGS,
  SELECTALLCATEGORYS
} from '../api/api.js'
import router from '../router/index'
export default {
  components: {
    ArticleListInfo,
    Index
  },
  setup() {
    const ArticleListInfo = ref(null)
    onMounted(() => {
      currentTime()
      getBlogNums()
      getAllTags()
      getAllCategorys()
      ArticleListInfo.value.selectArticles()
    })
    const time = ref()
    function currentTime() {
      setInterval(getTime, 500)
    }
    const getTime = () => {
      const hh = new Date().getHours()
      const mf =
        new Date().getMinutes() < 10
          ? '0' + new Date().getMinutes()
          : new Date().getMinutes()
      const ss =
        new Date().getSeconds() < 10
          ? '0' + new Date().getSeconds()
          : new Date().getSeconds()
      time.value = hh + ' : ' + mf + ' : ' + ss
    }
    // 博客数量信息
    const blogNums = reactive({
      articleNums: 0,
      categoryNums: 0,
      tagNums: 0
    })
    // 查询博客信息
    const getBlogNums = () => {
      SELECTBLOGNUMS().then((response) => {
        if (response.code === 0) {
          const { articleNums, categoryNums, tagNums } = response.data
          blogNums.articleNums = articleNums
          blogNums.categoryNums = categoryNums
          blogNums.tagNums = tagNums
        }
      })
    }
    // 标签信息
    const allTags = ref([])
    // 查询所有标签信息
    const getAllTags = () => {
      SELECTALLTAGS().then((response) => {
        if (response.code === 0) {
          allTags.value = response.data
        }
      })
    }
    // 分类信息
    const allCategorys = ref([])
    // 查询所有分类信息
    const getAllCategorys = () => {
      SELECTALLCATEGORYS().then((response) => {
        if (response.code === 0) {
          allCategorys.value = response.data
        }
      })
    }

    // 点击标签
    const onChangeTag = (tag_id) => {
      router.push({ name: 'ArticleList', params: { tag_id: tag_id } })
    }
    // 点击分类
    const onChangeCategory = (category_id) => {
      router.push({ name: 'ArticleList', params: { category_id: category_id } })
    }

    // 文章搜索表单
    const selectArticleFrom = reactive({
      title: ''
    })

    // 点击搜索
    const onSearch = () => {
      router.push({
        name: 'ArticleList',
        params: { title: selectArticleFrom.title }
      })
    }

    return {
      ArticleListInfo,
      time,
      blogNums,
      allTags,
      allCategorys,
      onChangeTag,
      onChangeCategory,
      onSearch,
      selectArticleFrom
    }
  }
}
</script>
<style>
:root {
  --subject-color: #99cccc;
}
</style>
<style src="../css/home.css" scoped>
</style>
